// 开发
export const D_PREFIX = "http://api-product.dev.huxiu.smart4s.com"

// 测试
export const T_PREFIX = "https://t-api-ad-product.huxiu.com"

// 生产pre
export const P_PREFIX = "https://api-ad-product.huxiu.com"

//查询广告列表信息
export const API_QUERY_ADV_LIST_INFO = "/Api/Product/SDK/Advert/Query/queryAdvertListInfo"

//提交广告统计信息（批量）
export const API_SUBMIT_ADVERT_STATISTICS_INFO_BY_BATCH = "/Api/Product/SDK/Advert/Submit/submitAdvertStatisticsInfoByBatch"





