import { D_PREFIX, P_PREFIX, T_PREFIX } from "../api/api";

/**
 * [对Date的扩展，将 Date 转化为指定格式的String]
 * 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符，
 * 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
 * 例子：
 * @param (new Date()).Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423
 * @param (new Date()).Format("yyyy-M-d h:m:s.S") ==> 2006-7-2 8:9:4.18
 * @return string
 */
Date.prototype.Format = function (fmt) {
  // author: meizz
  var o = {
    "M+": this.getMonth() + 1, // 月份
    "d+": this.getDate(), // 日
    "h+": this.getHours(), // 小时
    "m+": this.getMinutes(), // 分
    "s+": this.getSeconds(), // 秒
    "q+": Math.floor((this.getMonth() + 3) / 3), // 季度
    S: this.getMilliseconds(), // 毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (this.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
  return fmt;
};

/**
 * [获取接口请求前缀]
 * @param object
 * @return string
 */
export function getPrefix(srcParams) {
  let _PREFIX = "";
  switch (srcParams.env) {
    case "D":
      _PREFIX = D_PREFIX;
      break;
    case "T":
      _PREFIX = T_PREFIX;
      break;
    case "P":
      _PREFIX = P_PREFIX;
      break;
    default:
      console.log("参数值有误，请检查");
  }
  return _PREFIX;
}

/**
 * [获取localStorage]
 * @param {key,values} 缓存键，缓存值
 * @return object
 */
export function getLocalStorage(key, values, type) {
  var params;
  if (!window.localStorage) {
    console.log("浏览器不支持localstorage");
    params = values;
  } else {
    let storage = window.localStorage;
    var advIdobj = storage.getItem(key);
    if (type == 0) {
      params = advIdobj;
    } else {
      var jsonAdvObj = JSON.parse(advIdobj);
      params = jsonAdvObj;
    }
  }
  return params;
}

/**
 * [设置localStorage]
 * @param {key,values,type,cb} 缓存键，缓存值，缓存类型（0：数组；1：对象），回调函数
 * @return callback
 */
export function setLocalStorage(key, values, type) {
  if (values) {
    try {
      var str1 = values.split("[");
      var str2 = str1[1].split("]")[0];
    } catch (e) {
      // console.log('values end',e)
    }
  }

  if (!window.localStorage) {
    console.log("浏览器不支持localstorage");
    return false;
  } else {
    let storage = window.localStorage;
    var advIdobj = storage.getItem(key);
    let arr = [];
    if (advIdobj) {
      var jsonAdvObj;
      if (type == 0) {
        arr.push(advIdobj);
        arr.push(str2);
        storage.setItem(key, arr);
      } else {
        if (advIdobj === "undefined") {
          window.localStorage.removeItem("initData");
          storage.setItem(key, JSON.stringify(values));
        } else {
          console.log();
          jsonAdvObj = JSON.parse(advIdobj);
          jsonAdvObj = {
            ...jsonAdvObj,
            ...values,
          };
          storage.setItem(key, JSON.stringify(jsonAdvObj));
        }
      }
    } else {
      if (type == 0) {
        arr.push(str2);
        storage.setItem(key, arr);
      } else {
        storage.setItem(key, JSON.stringify(values));
      }
    }
  }
}

/**
 * [获取URL中的参数名及参数值的集合]
 * 示例URL:http://htmlJsTest/getrequest.html?uid=admin&rid=1&fid=2&name=小明
 * @param {[string]} urlStr [当该参数不为空的时候，则解析该url中的参数集合]
 * @return {[string]}       [参数集合]
 */
export function GetRequest(urlStr) {
  if (typeof urlStr == "undefined") {
    var url = decodeURI(location.search); //获取url中"?"符后的字符串
  } else {
    var url = "?" + urlStr.split("?")[1];
  }
  var theRequest = new Object();
  if (url.indexOf("?") != -1) {
    var str = url.substr(1);
    var strs = str.split("&");
    for (var i = 0; i < strs.length; i++) {
      theRequest[strs[i].split("=")[0]] = decodeURI(strs[i].split("=")[1]);
    }
  }
  return theRequest;
}

// 防抖
export const debounce = (fn, delayTime) => {
  let timerId;
  return function () {
    let th = this;
    let args = arguments;
    timerId && clearTimeout(timerId);
    timerId = setTimeout(() => fn.apply(th, args), delayTime);
  };
};
// 节流
export function throttle(callback, wait) {
  var timer = null;
  return function () {
    if (timer) {
      clearInterval(timer);
    }
    timer = setTimeout(function () {
      callback();
      timer = null;
    }, 500);
  };
}

/**
 * [获取浏览器ua]
 * @return boolean
 */
export function getUa() {
  // 判断是否在微信小程序中打开
  var ua = window.navigator.userAgent.toLowerCase();
  return ua.match(/MicroMessenger/i) == "micromessenger";
}

/*
 * @desc  判断浏览器的版本以及浏览器内核
 */
export function browser() {
  var agent = navigator.userAgent.toLowerCase(),
    opera = window.opera,
    browser = {
      //检测当前浏览器是否为IE
      ie: /(msie\s|trident.*rv:)([\w.]+)/.test(agent),

      //检测当前浏览器是否为Opera
      opera: !!opera && opera.version,

      //检测当前浏览器是否是webkit内核的浏览器
      webkit: agent.indexOf(" applewebkit/") > -1,

      //检测当前浏览器是否是运行在mac平台下
      mac: agent.indexOf("macintosh") > -1,

      //检测当前浏览器是否处于“怪异模式”下
      quirks: document.compatMode == "BackCompat",
    };

  //检测当前浏览器内核是否是gecko内核
  browser.gecko =
    navigator.product == "Gecko" &&
    !browser.webkit &&
    !browser.opera &&
    !browser.ie;

  var version = 0;

  // Internet Explorer 6.0+
  if (browser.ie) {
    var v1 = agent.match(/(?:msie\s([\w.]+))/);
    var v2 = agent.match(/(?:trident.*rv:([\w.]+))/);
    if (v1 && v2 && v1[1] && v2[1]) {
      version = Math.max(v1[1] * 1, v2[1] * 1);
    } else if (v1 && v1[1]) {
      version = v1[1] * 1;
    } else if (v2 && v2[1]) {
      version = v2[1] * 1;
    } else {
      version = 0;
    }

    //检测浏览器模式是否为 IE11 兼容模式
    browser.ie11Compat = document.documentMode == 11;

    //检测浏览器模式是否为 IE9 兼容模式
    browser.ie9Compat = document.documentMode == 9;

    //检测浏览器模式是否为 IE10 兼容模式
    browser.ie10Compat = document.documentMode == 10;

    //检测浏览器是否是IE8浏览器
    browser.ie8 = !!document.documentMode;

    //检测浏览器模式是否为 IE8 兼容模式
    browser.ie8Compat = document.documentMode == 8;

    //检测浏览器模式是否为 IE7 兼容模式
    browser.ie7Compat =
      (version == 7 && !document.documentMode) || document.documentMode == 7;

    //检测浏览器模式是否为 IE6 模式 或者怪异模式
    browser.ie6Compat = version < 7 || browser.quirks;

    browser.ie9above = version > 8;

    browser.ie9below = version < 9;
  }

  // Gecko.
  if (browser.gecko) {
    var geckoRelease = agent.match(/rv:([\d\.]+)/);
    if (geckoRelease) {
      geckoRelease = geckoRelease[1].split(".");
      version =
        geckoRelease[0] * 10000 +
        (geckoRelease[1] || 0) * 100 +
        (geckoRelease[2] || 0) * 1;
    }
  }

  //检测当前浏览器是否为Chrome, 如果是，则返回Chrome的大版本号
  if (/chrome\/(\d+\.\d)/i.test(agent)) {
    browser.chrome = +RegExp["\x241"];
  }

  //检测当前浏览器是否为Safari, 如果是，则返回Safari的大版本号
  if (
    /(\d+\.\d)?(?:\.\d)?\s+safari\/?(\d+\.\d+)?/i.test(agent) &&
    !/chrome/i.test(agent)
  ) {
    browser.safari = +(RegExp["\x241"] || RegExp["\x242"]);
  }

  // Opera 9.50+
  if (browser.opera) version = parseFloat(opera.version());

  // WebKit 522+ (Safari 3+)
  if (browser.webkit)
    version = parseFloat(agent.match(/ applewebkit\/(\d+)/)[1]);

  //检测当前浏览器版本号
  browser.version = version;

  return browser;
}
export function getBrowser(n) {  
  var ua = navigator.userAgent.toLowerCase(),  
      s,  
      name = '',  
      ver = 0;  
  //探测浏览器
  (s = ua.match(/msie ([\d.]+)/)) ? _set("ie", _toFixedVersion(s[1])):  
  (s = ua.match(/firefox\/([\d.]+)/)) ? _set("firefox", _toFixedVersion(s[1])) :  
  (s = ua.match(/chrome\/([\d.]+)/)) ? _set("chrome", _toFixedVersion(s[1])) :  
  (s = ua.match(/opera.([\d.]+)/)) ? _set("opera", _toFixedVersion(s[1])) :  
  (s = ua.match(/version\/([\d.]+).*safari/)) ? _set("safari", _toFixedVersion(s[1])) : 0;  
  
  function _toFixedVersion(ver, floatLength) {  
    ver = ('' + ver).replace(/_/g, '.');  
    floatLength = floatLength || 1;  
    ver = String(ver).split('.');  
    ver = ver[0] + '.' + (ver[1] || '0');  
    ver = Number(ver).toFixed(floatLength);  
    return ver;  
  }  
  function _set(bname, bver) {  
    name = bname;  
    ver = bver;  
  }  
  return (n == 'n' ? name : (n == 'v' ? ver : name + ver));  
};  
  
// var neihe = getBrowser("n"); // 所获得的就是浏览器所用内核。
// var banben = getBrowser("v");// 所获得的就是浏览器的版本号。
//  var browser = getBrowser();// 所获得的就是浏览器内核加版本号。
