import { formatParams } from "./transform";

/**
 * [异步请求函数]
 * @param {[object]}
 * {
    url: "./TestXHR.aspx",              //请求地址
    type: "POST",                       //请求方式
    async: true,                        //同步异步设置
    timeout: 8000,                      //超时设置
    data: {name: "super", age: 20},     //请求参数
    dataType: "json",
    success: function (response, xml) {
        // 此处执行请求成功后的回调
    },
    fail: function (status) {
        //  此处为请求失败后的回调
    }
 * @return {[string]}       [参数集合]
 * */
export function _ajax(options) {
  options = options || {};
  options.type = (options.type || "GET").toUpperCase();
  options.dataType = options.dataType || "json";
  // options.async = options.async || true;
  options.timeout = options.timeout || 500; //超时处理，默认8s
  // console.log('as',options)
  var params = formatParams(options.data);

  //创建 - 非IE6 - 第一步
  if (window.XMLHttpRequest) {
    var xhr = new XMLHttpRequest();
  } else {
    //IE6及其以下版本浏览器
    var versions = [
      "MSXML2.XmlHttp.6.0",
      "MSXML2.XmlHttp.5.0",
      "MSXML2.XmlHttp.4.0",
      "MSXML2.XmlHttp.3.0",
      "MSXML2.XmlHttp.2.0",
      "Microsoft.XmlHttp",
    ];

    var xhr;
    for (var i = 0; i < versions.length; i++) {
      try {
        xhr = new ActiveXObject(versions[i]);
        break;
      } catch (e) {}
    }
  }

  //接收 - 第三步
  xhr.onreadystatechange = function () {
    if (xhr.readyState == 4) {
      var status = xhr.status;
      if (status >= 200 && status < 300) {
        options.success && options.success(xhr.responseText, xhr.responseXML);
      } else {
        options.fail && options.fail(status);
      }
    }
  };

  //连接 和 发送 - 第二步
  if (options.type == "GET") {
    xhr.open("GET", options.url + "?" + params, true);
    xhr.send(null);
  } else if (options.type == "POST") {
    xhr.open("POST", options.url, options.async);
    //设置表单提交时的内容类型
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.send(params);
  }
}
