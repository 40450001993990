import { GetRequest, browser, getBrowser } from "./utils/utils";
import { transformArray, transformMoreArray } from "./utils/transform";
import { _ajax } from "./utils/request";
import { CODE_SUCCESS } from "./utils/code";
import {
  API_QUERY_ADV_LIST_INFO,
  API_SUBMIT_ADVERT_STATISTICS_INFO_BY_BATCH,
} from "./api/api";
import { setLocalStorage, getLocalStorage, getPrefix } from "./utils/utils";
import { transformAdvInfo, transformLocalStorage } from "./utils/transform";

import {
  OPERATOR_APP_INFO,
  CUSTOM_PRODUCT_SN_LIST,
  CUSTOM_ADVERT_TYPE_SN_LIST,
  CUSTOM_SCENE_SN_LIST,
  PAGING_FLAG,
} from "./utils/global";


// import { fetch } from './utils/fetch.umd'

// 广告列表存储器
let _advInfo = [];
// 广告错误信息
let _advInfoMsgInfo = {};
// fail回调
let _advInfoFailInfo = {};
//广告点击上报错误信息
let _advReportMsgInfo = {};
//fail回调
let _advReportFailInfo = {};

/**
 * [初始化]
 * @param {clientTypeSn: "WP_PC",env: "T", productSnList: "PR_HuXiu_PC"},type:self-外部调用 [参数集合]
 * @return {[string]}
 */
function _init(params) {
  try {
    var srcDom = document.getElementById("advManager").getAttribute("src");
    var srcParams = GetRequest(srcDom);
    if (srcParams && srcParams.env) {
      params = {
        ...params,
        env: srcParams.env,
        ...(srcParams.customProductSnList &&
          transformArray(
            srcParams.customProductSnList.split(","),
            "customProductSnList"
          )),
      };
      // 加载sdk，默认调取广告列表信息
      queryAdvListInfo(params);
    }
  } catch (e) {
    console.log(e);
  }
}

// 默认调用初始化
_init({
  operatorAppInfo: JSON.stringify(OPERATOR_APP_INFO),
  ...transformArray(CUSTOM_PRODUCT_SN_LIST, "customProductSnList"),
  pagingFlag: PAGING_FLAG,
  launchTimeBegin: new Date().Format("yyyy-MM-dd hh:mm:ss"),
  launchTimeEnd: new Date().Format("yyyy-MM-dd hh:mm:ss"),
});

/**
 * [查询广告列表信息]
 * @param {[string]}
 * @return {[string]}       [参数集合]
 */
function queryAdvListInfo(params) {
  const _PREFIX = getPrefix(params);
  _ajax({
    url: `${_PREFIX}${API_QUERY_ADV_LIST_INFO}`, //请求地址
    type: "POST", //请求方式
    data: { ...params }, //请求参数
    dataType: "json",
    async: false,
    success: function (response, xml) {
      // 此处放成功后执行的代码
      const res = JSON.parse(response);
      if (res.errCode === CODE_SUCCESS) {
        _advInfo = transformAdvInfo(res.data.advertListInfo);
        // _advInfo = res.data;
        setLocalStorage("initData", params, 1);
      } else {
        _advInfoMsgInfo = {
          ...res,
        };
        window.localStorage.removeItem("initData");
      }
    },
    fail: function (status) {
      // 此处放失败后执行的代码
      _advInfoFailInfo = {
        title: "advInfoFail",
        status: status,
      };
      window.localStorage.removeItem("initData");
    },
  });
}

/**
 * [查询广告列表信息]
 * @param {[string]}
 * @return {[string]}       [参数集合]
 */
export function _queryAdvListInfo(AdvRequest, AdvCallBack, fail) {
  const _PREFIX = getPrefix(AdvRequest);
  _ajax({
    url: `${_PREFIX}${API_QUERY_ADV_LIST_INFO}`, //请求地址
    type: "POST", //请求方式
    data: {
      launchTimeBegin: new Date().Format("yyyy-MM-dd hh:mm:ss"),
      launchTimeEnd: new Date().Format("yyyy-MM-dd hh:mm:ss"),
      ...transformMoreArray(AdvRequest),
    }, //请求参数
    dataType: "json",
    async: true,
    success: function (response, xml) {
      // 此处放成功后执行的代码
      const res = JSON.parse(response);
      AdvCallBack && AdvCallBack(res);

      if (res.errCode === CODE_SUCCESS) {
        // uccessCb && uccessCb(res.data)
        // _advInfo = transformAdvInfo(res.data.advertListInfo)
        _advInfo = res.data.advertListInfo;
        setLocalStorage("initData", AdvRequest, 1);
      } else {
        // uccessCb && uccessCb(res.data)
        window.localStorage.removeItem("initData");
      }
    },
    fail: function (status) {
      // 此处放失败后执行的代码
      fail && fail(status);
      window.localStorage.removeItem("initData");
    },
  });
}

/**
 * [广告统计]
 * @param {params} 广告上送所需参数
 * @return { }
 */
function _advReport(AdvRequest, ReportCallBack, fail) {
  if (
    AdvRequest &&
    AdvRequest.advertStatisticsInfoList &&
    Array.isArray(AdvRequest.advertStatisticsInfoList)
  ) {
    var advertStatisticsInfoList_c = transformLocalStorage(
      AdvRequest.advertStatisticsInfoList
    );
  } else {
    console.log("请检查入参");
  }

  if (AdvRequest && AdvRequest.env) {
    var _PREFIX = getPrefix(AdvRequest);
  } else {
    console.log("输入参数有误");
    return;
  }

  _ajax({
    url: `${_PREFIX}${API_SUBMIT_ADVERT_STATISTICS_INFO_BY_BATCH}`,
    type: "POST",
    data: {
      env: AdvRequest.env,
      operatorAppInfo: JSON.stringify(OPERATOR_APP_INFO),
      ...transformArray(advertStatisticsInfoList_c, "advertStatisticsInfoList"),
    },
    dataType: "json",
    async: true,
    success: function (response, xml) {
      // 此处放成功后执行的代码
      const res = JSON.parse(response);
      ReportCallBack && ReportCallBack(res);
      if (res.errCode === CODE_SUCCESS) {
        AdvRequest.advertStatisticsInfoList[0].operateType === "Click"
          ? window.localStorage.removeItem("advIdobjC")
          : window.localStorage.removeItem("advIdobjE");
      } else {
        _advReportMsgInfo = {
          ...res,
        };
        AdvRequest.advertStatisticsInfoList[0].operateType === "Click"
          ? setLocalStorage(
              "advIdobjC",
              JSON.stringify(AdvRequest.advertStatisticsInfoList),
              0
            )
          : setLocalStorage(
              "advIdobjE",
              JSON.stringify(AdvRequest.advertStatisticsInfoList),
              0
            );
      }
    },
    fail: function (status) {
      // 此处放失败后执行的代码
      fail && fail(status);
      _advReportFailInfo = {
        title: "advReportFail",
        status: status,
      };
      AdvRequest.advertStatisticsInfoList[0].operateType === "Click"
        ? setLocalStorage(
            "advIdobjC",
            JSON.stringify(AdvRequest.advertStatisticsInfoList),
            0
          )
        : setLocalStorage(
            "advIdobjE",
            JSON.stringify(AdvRequest.advertStatisticsInfoList),
            0
          );
    },
  });
}

export {
  _advInfo,
  _advInfoMsgInfo,
  _advInfoFailInfo,
  _advReportMsgInfo,
  _advReportFailInfo,
  _init,
  _advReport,
};
