import { getLocalStorage } from "./utils";

/**
 * [ajax入参是数据转换]
 * @param {key,values,type,cb} 缓存键，缓存值，缓存类型（0：数组；1：对象），回调函数
 * @return callback
 */
export function transformArray(array, key) {
  var array_c = [];
  for (var i = 0; i < array.length; i++) {
    array_c[`${key}[${i}]`] = array[i];
  }
  return array_c;
}

/**
 * [ajax入参是数据转换]
 * @param {key,values,type,cb}
 * @return object
 */
export function transformMoreArray(params) {
  var obj = {};
  for (let key in params) {
    if (Array.isArray(params[key]) && params[key].length > 0) {
      obj = {
        ...obj,
        ...transformArray(params[key], key),
      };
    } else {
      obj = {
        ...obj,
        [key]: params[key],
      };
    }
  }
  return obj;
}

/**
 * [获取缓存数据]
 * @param   object
 * @return  array
 */
export function transformLocalStorage(params) {
  var advIdobj;
  params[0].operateType === "Click"
    ? (advIdobj = getLocalStorage("advIdobjC", params, 0))
    : (advIdobj = getLocalStorage("advIdobjE", params, 0));
  var advertStatisticsInfoList_c = [];
  if (params && params.length > 0) {
    for (var i = 0; i <= params.length - 1; i++) {
      advertStatisticsInfoList_c.push(JSON.stringify(params[i]));
    }
  } else {
    console.log("参数有误");
    return;
  }

  if (advIdobj !== null && advIdobj !== undefined) {
    // console.log('advIdobj',advIdobj)
    var arrr = advIdobj.split('"},');
    // console.log('arrr',arrr)
    for (var i = 0; i <= arrr.length - 1; i++) {
      if (arrr[i] !== "" && i == arrr.length - 1) {
        var str = arrr[i];
        advertStatisticsInfoList_c.push(str);
      } else {
        var str = arrr[i] + '"}';
        advertStatisticsInfoList_c.push(str);
      }
    }
  }
  return advertStatisticsInfoList_c;
}

/**
 * [广告列表数据筛选]
 * @param object
 * @return object
 */
export function transformAdvInfo(params) {
  // console.log('transformAdvInfo', params);
  let objParams = [];
  for (var i = 0; i <= params.length - 1; i++) {
    let {
      advertInfo, //⼴告信息
      advertClickModeInfo, //⼴告点击模式信息
      advertClickConfigInfo, //⼴告点击配置信息
      advertDeviceLocationListInfo, //⼴告设备位置列表信息
      advertDeviceOperatorListInfo, //⼴告设备运营商列表信息
      advertDeviceOSListInfo, //⼴告设备操作系统列表信息
      advertLaunchModeInfo, //⼴告发布模式信息
      advertLaunchTimeListInfo, //⼴告发布时间列表信息
      advertMaterialTypeInfo, //⼴告物料类型信息
      advertMaterialStyleInfo, //广告物料样式信息
      advertMaterialFileListInfo, //⼴告物料列表信息
      advertMonitorInfo, //曝光、点击上送
      advertTagListInfo, //⼴告标签列表信息
      advertGroupInfo, //⼴告组信息
      sceneAdvertInfo, //场景⼴告信息
      advertTypeInfo, //广告类型信息
      sceneAdvertTypeInfo, //场景⼴告类型信息
      sceneInfo, //场景信息
      productInfo, //产品信息
    } = params[i];
    objParams.push({
      advertInfo,
      advertClickConfigInfo,
      advertLaunchTimeListInfo,
      advertMaterialFileListInfo,
      advertMaterialTypeInfo,
      advertMaterialStyleInfo,
      advertMonitorInfo,
      advertTagListInfo,
      sceneAdvertInfo,
      advertTypeInfo,
      sceneInfo,
    });
  }
  // console.log('objParams',objParams);
  return objParams;
}

//格式化参数
export function formatParams(data) {
  var arr = [];
  for (var name in data) {
    arr.push(encodeURIComponent(name) + "=" + encodeURIComponent(data[name]));
  }
  //   arr.push(("v=" + Math.random()).replace(".", ""));
  return arr.join("&");
}
