
// 操作应用信息
export const OPERATOR_APP_INFO = {clientTypeSn: "WP_PC"}
// 产品编码列表 customProductSnList
export const CUSTOM_PRODUCT_SN_LIST = ["HuXiu_PC"]
// 自定义广告类型编码列表 customAdvertTypeSnList
export const CUSTOM_ADVERT_TYPE_SN_LIST = ["FEED", "TOP_BANNER", "MIDDLE_BANNER", "BOTTOM_BANNER", "RIGHT"]
//自定义场景编码列表customSceneSnList
export const CUSTOM_SCENE_SN_LIST = ["pc_home","pc_articles","pc_content","pc_event","pc_collection","pc_timeline","pc_moment","pc_channel_110","pc_channel_106","pc_channel_21","pc_channel_111","pc_channel_114","pc_channel_103","pc_channel_105","pc_channel_112","pc_channel_113","pc_channel_115","pc_channel_22","pc_channel_102","pc_channel_2","pc_channel_107","pc_channel_4","pc_channel_10"]
// 是否分页（0：不分页；1：分页）
export const PAGING_FLAG = "0"

